'use strict';

/*
exported
vehicleTypesSelectorDirective
 */

function vehicleTypesSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/vehicle-types/vehicle-types-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      label: '@',
      placeholder: '@',
      multiSelector: '<',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};
      scope.placeholder = scope.placeholder || 'Select vehicle type...';
      scope.displayLabel = scope.showLabel === undefined ? true : scope.showLabel;
      var query = {};

      if(scope.multiSelector) {
        scope.selectedVehicleTypes = Array.isArray(scope.ngModel) && scope.ngModel.map(str => {
          return {
            name: str,
            id: str
          };
        }) || [];
      }

      Restangular
        .all('vehicles/types')
        .getList(query)
        .then(function (vehicleTypes) {
          scope.vehicleTypes = vehicleTypes;
        });

      // End of init
      ngModelCtrl.$validators.required = function (modelValue) {
        return !!(modelValue);
      };

      // From model to view
      ngModelCtrl.$formatters.push(function (modelValue) {
        return { vehicleType: modelValue };
      });

      // From view to model
      ngModelCtrl.$parsers.push(function (viewValue) {
        if(scope.multiSelector && Array.isArray(viewValue.vehicleType)){
          const validTypesObj  = {};
          scope.vehicleTypes.forEach(function(type) { validTypesObj[type.name.toLowerCase()] = type })
          return viewValue.vehicleType.map(function(type)  { return validTypesObj[type.name.toLowerCase()]});
        }
        return viewValue.vehicleType.key;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.vehicleType) {
          var vehicleType = ngModelCtrl.$viewValue.vehicleType;
          Restangular
            .one('vehicles/types', vehicleType)
            .get()
            .then(function(vehicleType) {
              scope.selected.vehicleType = vehicleType;
            })
            .catch(function(error) {
              scope.selected.vehicleType = null;
            });
        } else {
          scope.selected.vehicleType = ngModelCtrl.$viewValue.vehicleType;
        }
      };

      // Update model
      scope.updateVehicleType = function (vehicleType) {
        if(scope.multiSelector){        
          scope.selectedVehicleTypes.push(vehicleType);
          ngModelCtrl.$setViewValue({ vehicleType:  scope.selectedVehicleTypes});
        }else {
          ngModelCtrl.$setViewValue({ vehicleType });
        }
      };

      scope.removeVehicleType = function (vehicleType) {
        scope.selectedVehicleTypes = scope.selectedVehicleTypes.filter(item => item.id !== vehicleType.id);
        ngModelCtrl.$setViewValue({ vehicleType:  scope.selectedVehicleTypes});
      };


      // Refresh companies list on search
      scope.refreshVehicleTypesList = function (search) {
        if (!search) {
          return;
        }

        var refreshQuery = { search: search };
        Restangular
          .all('vehicles/types')
          .getList(refreshQuery)
          .then(function (vehicleTypes) {
            scope.vehicleTypes = vehicleTypes;
          });
      };
    }
  };
}

'use strict';

/*
exported
vehicleClassesSelectorDirective
 */

function vehicleClassesSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/vehicle-classes/vehicle-classes-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      label: '@label',
      placeholder: '@placeholder',
      multiSelector: '<',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};
      if(scope.multiSelector) {
        scope.selectedVehicleClasses = Array.isArray(scope.ngModel) && scope.ngModel.map(str => {
          return {
            name: str,
            id: str,
          };
        }) || [];
      }
      scope.placeholder = scope.placeholder || 'Select vehicle class...';
      scope.displayLabel = scope.showLabel === undefined ? true : scope.showLabel;
      var query = {};

      Restangular
        .all('vehicles/classes')
        .getList(query)
        .then(function (vehicleClasses) {
          scope.vehicleClasses = vehicleClasses;
        });

      // End of init
      ngModelCtrl.$validators.required = function (modelValue) {
        return !!(modelValue);
      };

      // From model to view
      ngModelCtrl.$formatters.push(function (modelValue) {
        return { vehicleClass: modelValue };
      });

      // From view to model
      ngModelCtrl.$parsers.push(function (viewValue) {
        if(scope.multiSelector && Array.isArray(viewValue.vehicleClass)){
          const validClassesObj  = {};
          scope.vehicleClasses.forEach(function(vClass) { validClassesObj[vClass.name.toLowerCase()] = vClass })
          return viewValue.vehicleClass.map(function(vClass)  { return validClassesObj[vClass.name.toLowerCase()]});
        }
        return viewValue.vehicleClass.key;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.vehicleClass) {
          var vehicleClass = ngModelCtrl.$viewValue.vehicleClass;

          Restangular
            .one('vehicles/classes', vehicleClass)
            .get()
            .then(function(vehicleClass) {
              scope.selected.vehicleClass = vehicleClass;
            })
            .catch(function() {
              scope.selected.vehicleClass = null;
            });
        } else {
          scope.selected.vehicleClass = ngModelCtrl.$viewValue.vehicleClass;
        }
      };

      // Update model
      scope.updateVehicleClass = function (vehicleClass) {
        if(scope.multiSelector){        
          scope.selectedVehicleClasses.push(vehicleClass);
          ngModelCtrl.$setViewValue({ vehicleClass:  scope.selectedVehicleClasses});
        }else {
          ngModelCtrl.$setViewValue({ vehicleClass });
        }    
      };

      scope.removeVehicleClass = function (vehicleClass) {
        scope.selectedVehicleClasses = scope.selectedVehicleClasses.filter(item => item.id !== vehicleClass.id);
        ngModelCtrl.$setViewValue({ vehicleClass:  scope.selectedVehicleClasses});
      };


      // Refresh companies list on search
      scope.refreshVehicleClassesList = function (search) {
        if (!search) {
          return;
        }

        var refreshQuery = { search: search };
        Restangular
          .all('vehicles/classes')
          .getList(refreshQuery)
          .then(function (vehicleClasses) {
            scope.vehicleClasses = vehicleClasses;
          });
      };
    }
  };
}
